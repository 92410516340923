/**
 * @copyright KiteTrackers.
 */

import React from 'react'

// import { Router, navigate } from "@reach/router"

/**
 */
const About = () =>
{
    return (
        <section id="about" className="about">
            <div className="container" data-aos="fade-up">
                <div className="row">
                    <div className="col-lg-6 order-1 order-lg-2" data-aos="fade-left" data-aos-delay={100}>
                        <img src="assets/img/about.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 order-2 order-lg-1 content" data-aos="fade-right" data-aos-delay={100}>
                    {/* <h3>Social issues are not only for government and NGOs to solve.</h3> */}
                    <h3>{window._cfgAbout.title.t}</h3>
                    <p className="font-italic">
                        {/* Our goal is to... */}
                        {window._cfgAbout.preL.t}
                    </p>
                    <ul>
                        {
                            window._cfgAbout.list.map( (x, i) => (
                            <li key={i}><i className="ri-check-double-line" /> {x.t}</li>
                            ) )
                        }

                        {/*
                        <li><i className="ri-check-double-line" /> Promote social issues to be seen as opportunities and help with establishing for-profit social enterprises.</li>
                        <li><i className="ri-check-double-line" /> Create network of entrepreneurs and experienced and skilled personnel to addres social issues together.</li>
                        <li><i className="ri-check-double-line" /> Translate some of the burden from Government and NGOs to general public with innovative ideas.</li>
                        */}
                    </ul>
                    <p>
                        {/* In this time and age when we are seeing an influx of emergin technologies, innovative concepts and business innovation, we strongly believe that the social issues that couldn't be addressed before, are ready to be solved by for-profit social enterprises. This local involvement and use of innovation can lead to a sustainable society and maybe a a future that we all dream of. */}
                        {window._cfgAbout.postL.t}
                    </p>
                    </div>
                </div>
            </div>
        </section>
    )
}   // About

/**
 */
export default About











