/**
 * @copyright KiteTrackers.
 */

import React from 'react'

// import { Router, navigate } from "@reach/router"

/**
 */
const Counts = () =>
{
    return (
        <section id="counts" className="counts">
            <div className="container" data-aos="fade-up">
            <div className="row no-gutters">
                <div className="image col-xl-5 d-flex align-items-stretch justify-content-center justify-content-lg-start" data-aos="fade-right" data-aos-delay={100} />
                <div className="col-xl-7 pl-0 pl-lg-5 pr-lg-1 d-flex align-items-stretch" data-aos="fade-left" data-aos-delay={100}>
                <div className="content d-flex flex-column justify-content-center">
                    <h3>Where do we stand today</h3>
                    <p>
                    We are actively looking for senior executives, high level government officials and angel investors as our trackers as well as social enterpreneurs and enterprises, or as we call them the kites. The numbers below is what we are today.
                    </p>
                    <div className="row">
                    <div className="col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                        <i className="icofont-simple-smile" />
                        <span data-toggle="counter-up">02</span>
                        <p><strong>Startups</strong> have joined our journey to create a better Bangladesh and maybe the world.</p>
                        </div>
                    </div>
                    <div className="col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                        <i className="icofont-document-folder" />
                        <span data-toggle="counter-up">10</span>
                        <p><strong>Angel Investors</strong> has agreed come onboard our vision and would look into prospective business ideas with the aim to support them.</p>
                        </div>
                    </div>
                    <div className="col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                        <i className="icofont-clock-time" />
                        <span data-toggle="counter-up">22</span>
                        <p><strong>Senior Executives</strong> builds up our strong mentor network. With their hands-on guidance we believe that our startups would see to the end of their journeys.</p>
                        </div>
                    </div>
                    <div className="col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                        <i className="icofont-award" />
                        <span data-toggle="counter-up">30</span>
                        <p><strong>Team Members</strong> are making this dream come true. We are open to working with anyone as long as they can offer something to this venture and are passionate about solving social issues.</p>
                        </div>
                    </div>
                    </div>
                </div>{/* End .content*/}
                </div>
            </div>
            </div>
        </section>
    )
}   // Counts

/**
 */
export default Counts










