/**
 * @copyright KiteTrackers.
 */

import React from 'react'

// import { Router, navigate } from "@reach/router"

/**
 */
const Hero = () =>
{
    return (
        <section id="hero" className="d-flex align-items-center justify-content-center">
            <div className="container" data-aos="fade-up">
                <div className="row justify-content-center" data-aos="fade-up" data-aos-delay={150}>
                    <div className="col-xl-6 col-lg-8">
                        {/* <h1>Innovators, Creators and Entrepreneurs<span>.</span></h1> */}
                        {/* <h2>Committed to creating a better Bangladesh with social entrepreneurs</h2> */}
                        <h1>{window._cfgHeroes.title}<span>.</span></h1>
                        <h2>{window._cfgHeroes.sub}</h2>
                    </div>
                </div>
                <div className="row mt-5 justify-content-center" data-aos="zoom-in" data-aos-delay={250}>
                    {
                        window._cfgHeroes.list.map( (x, i) => (
                            <div className={x.cC} key={i}>
                                <div className="icon-box">
                                    <i className={x.cI} />
                                    <h3><a href='#features'>{x.t}</a></h3>
                                </div>
                            </div>
                        ) )
                    }
                    {/*
                    <div className="col-xl-2 col-md-4 col-6">
                        <div className="icon-box">
                            <i className="ri-store-line" />
                            <h3><a href>Early Stage</a></h3>
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-4 col-6 ">
                        <div className="icon-box">
                            <i className="ri-bar-chart-box-line" />
                            <h3><a href>Social Issues</a></h3>
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-4 col-6 mt-4 mt-md-0">
                        <div className="icon-box">
                            <i className="ri-calendar-todo-line" />
                            <h3><a href>Concepts / Ideas</a></h3>
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-4 col-6 mt-4 mt-xl-0">
                        <div className="icon-box">
                            <i className="ri-paint-brush-line" />
                            <h3><a href>Innovation</a></h3>
                        </div>
                    </div>
                    <div className="col-xl-2 col-md-4 col-6 mt-4 mt-xl-0">
                        <div className="icon-box">
                            <i className="ri-database-2-line" />
                            <h3><a href>Creativity</a></h3>
                        </div>
                    </div>
                    */}
                </div>
            </div>
        </section>
    )
}   // Hero

/**
 */
export default Hero










