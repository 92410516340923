/**
 * @copyright KiteTrackers.
 */

import React from 'react'

// import { Router, navigate } from "@reach/router"

/**
 */
const Clients = () =>
{
    return (
        <section id="clients" className="clients">
            <div className="container" data-aos="zoom-in">
                <div className="owl-carousel clients-carousel">
                    {
                        window._cfgClients.list.map( (x, i) => (
                            <img src={x.src} alt="" key={i} />
                        ) )
                    }
                    {/*                     
                    <img src="assets/img/clients/client-1.png" alt="" />
                    <img src="assets/img/clients/client-2.png" alt="" />
                    <img src="assets/img/clients/client-3.png" alt="" />
                    <img src="assets/img/clients/client-4.png" alt="" />
                    <img src="assets/img/clients/client-5.png" alt="" />
                    <img src="assets/img/clients/client-6.png" alt="" />
                    <img src="assets/img/clients/client-7.png" alt="" />
                    <img src="assets/img/clients/client-8.png" alt="" />
                    */}
                </div>
            </div>
        </section>
    )
}   // Clients

/**
 */
export default Clients










