/**
 * @copyright KiteTrackers.
 */

import React from 'react'

import { Router, navigate } from "@reach/router"

import Landing from './components/landing'

/**
 */
const App = () =>
{
    return (
        <Router>
            <Landing path='/' />
        </Router>
    )
}   // App

/**
 */
export default App













