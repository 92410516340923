/**
 * @copyright KiteTrackers.
 */

import React from 'react'

// import { Router, navigate } from "@reach/router"

/**
 */
const Team = () =>
{
    return (
        <section id="team" className="team">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>{window._cfgTeam.title.t}</h2>
                    <p>{window._cfgTeam.sub.t}</p>
                    {/*
                    <h2>Team</h2>
                    <p>Check our Team</p>
                    */}
                </div>
                <div className="row">
                    {
                        window._cfgTeam.list.map( (x, i) => (
                            <div className="col-lg-3 col-md-6 d-flex align-items-stretch" key={i}>
                                <div className="member" data-aos="fade-up" data-aos-delay={100}>
                                    <div className="member-img">
                                        <img src={x.img.src} className="img-fluid" alt="" />
                                        <div className="social">
                                            {
                                                x.social.twitter.url && x.social.twitter.url.length &&
                                                <a href><i className="icofont-twitter" /></a>
                                            }
                                            {
                                                x.social.twitter.url && x.social.twitter.url.length &&
                                                <a href><i className="icofont-facebook" /></a>
                                            }
                                            {
                                                x.social.twitter.url && x.social.twitter.url.length &&
                                                <a href><i className="icofont-instagram" /></a>
                                            }
                                            {
                                                x.social.twitter.url && x.social.twitter.url.length &&
                                                <a href><i className="icofont-linkedin" /></a>
                                            }
                                        </div>
                                    </div>
                                    <div className="member-info">
                                        <h4>{x.name.t}</h4>
                                        <span>{x.position.t}</span>
                                    </div>
                                </div>
                            </div>
                        ) )
                    }
                    {/*
                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                        <div className="member" data-aos="fade-up" data-aos-delay={100}>
                            <div className="member-img">
                            <img src="assets/img/team/team-1.jpg" className="img-fluid" alt="" />
                            <div className="social">
                                <a href><i className="icofont-twitter" /></a>
                                <a href><i className="icofont-facebook" /></a>
                                <a href><i className="icofont-instagram" /></a>
                                <a href><i className="icofont-linkedin" /></a>
                            </div>
                            </div>
                            <div className="member-info">
                            <h4>M Asif Amin</h4>
                            <span>Director</span>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                    <div className="member" data-aos="fade-up" data-aos-delay={200}>
                        <div className="member-img">
                        <img src="assets/img/team/team-2.jpg" className="img-fluid" alt="" />
                        <div className="social">
                            <a href><i className="icofont-twitter" /></a>
                            <a href><i className="icofont-facebook" /></a>
                            <a href><i className="icofont-instagram" /></a>
                            <a href><i className="icofont-linkedin" /></a>
                        </div>
                        </div>
                        <div className="member-info">
                        <h4>Sarah Jhonson</h4>
                        <span>Product Manager</span>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                    <div className="member" data-aos="fade-up" data-aos-delay={300}>
                        <div className="member-img">
                        <img src="assets/img/team/team-3.jpg" className="img-fluid" alt="" />
                        <div className="social">
                            <a href><i className="icofont-twitter" /></a>
                            <a href><i className="icofont-facebook" /></a>
                            <a href><i className="icofont-instagram" /></a>
                            <a href><i className="icofont-linkedin" /></a>
                        </div>
                        </div>
                        <div className="member-info">
                        <h4>Afraim Mansur</h4>
                        <span>Head of Marketing</span>
                        </div>
                    </div>
                    </div>
                    <div className="col-lg-3 col-md-6 d-flex align-items-stretch">
                    <div className="member" data-aos="fade-up" data-aos-delay={400}>
                        <div className="member-img">
                        <img src="assets/img/team/team-4.jpg" className="img-fluid" alt="" />
                        <div className="social">
                            <a href><i className="icofont-twitter" /></a>
                            <a href><i className="icofont-facebook" /></a>
                            <a href><i className="icofont-instagram" /></a>
                            <a href><i className="icofont-linkedin" /></a>
                        </div>
                        </div>
                        <div className="member-info">
                        <h4>Amanda Jepson</h4>
                        <span>Accountant</span>
                        </div>
                    </div>
                    </div>
                    */}
                </div>
            </div>
        </section>
    )
}   // Team

/**
 */
export default Team










