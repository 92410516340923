/**
 * @copyright KiteTrackers.
 */

import React from 'react'

// import { Router, navigate } from "@reach/router"

/**
 */
const Gap = ( props ) =>
{
    return (
        <section id={props.quote ? "hero" : 'testimonials'} className={props.quote ? "hero" : 'testimonials'}>
            <div className="container" data-aos="fade-up">
                <div className="row justify-content-center" data-aos="fade-up" data-aos-delay={150}>
                    <div className="col-xl-6 col-lg-8">
                        {
                            props.quote &&
                            <h1>{props.quote}<span>...</span></h1>
                        }
                        {
                            props.author &&
                            <h2>{props.author}</h2>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}   // Gap

/**
 */
export default Gap












