/**
 * @copyright KiteTrackers.
 */

import React from 'react'

// import { Router, navigate } from "@reach/router"

/**
 */
const Services = () =>
{
    return (
        <section id="services" className="services">
            <div className="container" data-aos="fade-up">
                <div className="section-title">
                    <h2>{window._cfgServices.title.t}</h2>
                    <p>{window._cfgServices.desc.t}</p>
                    {/*
                    <h2>Services</h2>
                    <p>How we will help you</p>
                    */}
                </div>
                <div className="row">
                    {
                        window._cfgServices.list.map( (x, i) => (
                            <div className={x.cC} data-aos="zoom-in" data-aos-delay={100} key={i}>
                                <div className="icon-box">
                                    <div className="icon"><i className={x.cI} /></div>
                                    <h4><a href>{x.title.t}</a></h4>
                                    <p>{x.desc.t}</p>
                                </div>
                            </div>
                        ) )
                    }
                    {/*
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch" data-aos="zoom-in" data-aos-delay={100}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bxl-dribbble" /></div>
                            <h4><a href>Concept / Idea</a></h4>
                            <p>We would help you look into your idea and give our opinion.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0" data-aos="zoom-in" data-aos-delay={200}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-file" /></div>
                            <h4><a href>Organise</a></h4>
                            <p>Organising the ideas and staying focused is what matters.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0" data-aos="zoom-in" data-aos-delay={300}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-tachometer" /></div>
                            <h4><a href>Pitch Deck</a></h4>
                            <p>Pitch deck is one of the first things that you need before you talk to prospective investors or contributors.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay={100}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-world" /></div>
                            <h4><a href>Finance Model</a></h4>
                            <p>Finance Model justifies your concept and attracts investors. It also shows if you would sustain in the long run.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay={200}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-slideshow" /></div>
                            <h4><a href>Computing / IT</a></h4>
                            <p>Well this is where we excel. We can give you best advice when it comes to Computing and IT.</p>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4" data-aos="zoom-in" data-aos-delay={300}>
                        <div className="icon-box">
                            <div className="icon"><i className="bx bx-arch" /></div>
                            <h4><a href>Mentor</a></h4>
                            <p>This is our USP. We will try to find you a Mentor who can guide you better in your specific feidl.</p>
                        </div>
                    </div>
                    */}
                </div>
            </div>
        </section>
    )
}   // Services

/**
 */
export default Services










