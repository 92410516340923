/**
 * @copyright KiteTrackers.
 */

import React from 'react'

// import { Router, navigate } from "@reach/router"

import Hdr from './hdr'
import Heroes from './heroes'

import About from './about'
import Clients from './clients'
import Features from './features'
import Services from './services'
import Cta from './cta'
import Portfolios from './portfolios'
import Counts from './counts'
import Testimonials from './testimonials'
import Team from './team'
import Contact from './contact'
import Ftr from './ftr'

import Gap from './gap'

/**
 */
const Landing = () =>
{
    return (
        <div>
            <Hdr />
            <Heroes />

            <main id='main'>
                <About />
                <Clients />
                <Gap
                    quote='Every single social and global issue of our day is a business opportunity in disguise'
                    author='Peter Drucker'
                />
                <Features />
                <Gap />
                <Services />
                {/*
                <Cta />
                <Portfolios />
                */}
                <Gap />
                <Counts />
                <Testimonials />
                <Team />
                <Gap />
                <Contact />
            </main>

            <Ftr />
        </div>
    )
}   // App

/**
 */
export default Landing










