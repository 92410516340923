/**
 * @copyright KiteTrackers.
 */

import React from 'react'

// import { Router, navigate } from "@reach/router"

/**
 */
const Features = () =>
{
    return (
        <section id="features" className="features">
            <div className="container" data-aos="fade-up">
                <div className="row">
                    <div className="image col-lg-6" style={{backgroundImage: 'url("assets/img/features.jpg")'}} data-aos="fade-right" />
                    <div className="col-lg-6" data-aos="fade-left" data-aos-delay={100}>
                        {
                            window._cfgFeatures.list.map( (x, i) => (
                                <div className={x.cC} data-aos="zoom-in" data-aos-delay={150}>
                                    <i className={x.cI} />
                                    <h4>{x.title.t}</h4>
                                    <p>{x.desc.t}</p>
                                </div>
                            ) )
                        }
                        {/*
                        <div className="icon-box mt-5 mt-lg-0" data-aos="zoom-in" data-aos-delay={150}>
                            <i className="bx bx-receipt" />
                            <h4>Early Stage Startups</h4>
                            <p>We have gone through the rigorous process of setting up startups and have not lost our passion for new startups, just yet! We are probably your best bet if you have an innovative idea.</p>
                        </div>
                        <div className="icon-box mt-5" data-aos="zoom-in" data-aos-delay={150}>
                            <i className="bx bx-cube-alt" />
                            <h4>Computing and Security</h4>
                            <p>Whether your idea needs work on low level boards or microchips or cloud computing, we are here to give you the best advice. Our team members are quite proud for their knowledge on security and data privacy.</p>
                        </div>
                        <div className="icon-box mt-5" data-aos="zoom-in" data-aos-delay={150}>
                            <i className="bx bx-images" />
                            <h4>Guidance and Mentoring</h4>
                            <p>As a new startup, support guidance from skilled and knowledgeable people from the respective sector can play a significant role. It also helps to quickly get the startup off the ground.</p>
                        </div>
                        <div className="icon-box mt-5" data-aos="zoom-in" data-aos-delay={150}>
                            <i className="bx bx-shield" />
                            <h4>Nothing beats some FREE advice</h4>
                            <p>Every Saturday we'll run sessions with early stage startups to guide them, point out their limitations and how to make the next step. And we'll do it all for FREE. Is there anything that beat some FREE advice?</p>
                        </div>
                        */}
                    </div>
                </div>
            </div>
        </section>
    )
}   // Features

/**
 */
export default Features










