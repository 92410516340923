/**
 * @copyright KiteTrackers.
 */

import React from 'react'

// import { Router, navigate } from "@reach/router"

/**
 */
const Hdr = () =>
{
    return (
        <header id="header" className="fixed-top ">
            <div className="container d-flex align-items-center justify-content-between">
            <h1 className="logo"><a href="/">Kite Trackers<span>...</span></a></h1>
            {/* Uncomment below if you prefer to use an image logo */}
            {/* <a href="/" class="logo"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>*/}
            <nav className="nav-menu d-none d-lg-block">
                <ul>
                <li className="active"><a href="/">Home</a></li>
                <li><a href="#about">About</a></li>
                <li><a href="#services">Services</a></li>
                {/* <li><a href="#portfolio">Portfolio</a></li> */}
                <li><a href="#team">Team</a></li>
                {/* <li class="drop-down"><a href="">Drop Down</a>
                <ul>
                <li><a href="#">Drop Down 1</a></li>
                <li class="drop-down"><a href="#">Deep Drop Down</a>
                    <ul>
                    <li><a href="#">Deep Drop Down 1</a></li>
                    <li><a href="#">Deep Drop Down 2</a></li>
                    <li><a href="#">Deep Drop Down 3</a></li>
                    <li><a href="#">Deep Drop Down 4</a></li>
                    <li><a href="#">Deep Drop Down 5</a></li>
                    </ul>
                </li>
                <li><a href="#">Drop Down 2</a></li>
                <li><a href="#">Drop Down 3</a></li>
                <li><a href="#">Drop Down 4</a></li>
                </ul>
            </li> */}
                <li><a href="#contact">Contact</a></li>
                </ul>
            </nav>{/* .nav-menu */}
            {/* <a href="#about" class="get-started-btn scrollto">Get Started</a> */}
            </div>
        </header>
    )
}   // Hdr

/**
 */
export default Hdr










