/**
 * @copyright KiteTrackers.
 */

import {combineReducers} from 'redux'

import Cfg from './Cfg'

export default combineReducers( {
    __cfg: Cfg,
} )


